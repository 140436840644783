.input {
  display: inline-block;
  margin-top: 30px;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 0px 8px 10px;
  gap: 10px;
  width: 70%;
  margin-left: 13%;

  height: 40px;

  background: #f4f4f4;
  mix-blend-mode: darken;
  border: 3px solid #f4f4f4;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 15px;

  font-family: "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  
}

::placeholder {
  color: #b5b5b5;
  opacity: 1; /* Firefox */
  text-transform: uppercase;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b5b5b5;
  text-transform: uppercase;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b5b5b5;
  text-transform: uppercase;
}

.text-area {
  height: 150px;
}

.red {
  box-shadow: 0px 0px 10px rgba(245, 1, 1, 0.4);
}

.round {
  border-radius: 50% !important;
}

.image {
  position: relative;
  /* width: 70%;
  height: 150px; */
  left: 15%;

  border: 3px solid #FFFFFF;
  box-shadow: 0px 4px 13px rgba(90, 90, 90, 0.25);
  border-radius: 5%;
}

.text {
  font-family: "Arial";
  font-style: normal;
  font-weight: 530;
  font-size: 13px;
  line-height: 17px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.42);
  margin-left: auto;
  margin-right: auto;
}

.red-text {
  color: rgba(175, 53, 53, 0.765);
  font-weight: 560;
}


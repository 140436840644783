.card {
  display: inline-block;
  margin-top: 30px;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 0px 8px 10px;
  gap: 10px;

  margin-left: 12%;
  width: 70%;
  height: 100px;

  background: #f4f4f4;
  mix-blend-mode: darken;
  border: 3px solid #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

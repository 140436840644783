.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px 11px;
  gap: 10px;
  margin-top: 10px;
  margin-left: 15%;

  width: 70%;
  height: 70px;

  background: #2aadb6;
  mix-blend-mode: darken;
  border: 3px solid #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 15px;

  font-family: "Arial";
  font-weight: 657;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
  font-variation-settings: "slnt" -1;

  
  /* Note: backdrop-filter has minimal browser support */
}

.add-image-button {
  display: inline-block;
  background: rgba(217, 217, 217, 0.75);
  border: 3px solid #ffffff;
  box-shadow: 0px 4px 13px rgba(90, 90, 90, 0.25);

  font-family: "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #ffffff;

  justify-content: center;
  align-items: center;
  border-radius: 5%;
}

.dummy {
  margin-top: 110%;
}

.hide {
  overflow: hidden;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.round {
  border-radius: 50%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-file {
  display: inline-block;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 10px 10px 10px;
  gap: 10px;
  margin-top: 30px;

  width: 70%;
  height: 70px;
  margin-left: 15%;

  background: #ffffff;
  mix-blend-mode: darken;
  border: 3px solid #2aadb6;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 15px;

  font-family: "Arial";
  font-weight: 657;
  font-size: 13px;
  line-height: 16px;

  align-items: center;
  text-transform: uppercase;

  color: #2aadb6;
  font-variation-settings: "slnt" -1;
}

.disabled {
  border: 3px solid #a2a0a0 !important;
  color: #a2a0a0 !important;
}

.remove-button {
  display: inline-block;
  flex-direction: row;
  align-items: flex-start;

  width: 20%;
  height: 30px;
  margin-left: 4%;

  background: #ffffff !important;
  mix-blend-mode: darken;
  border: 3px solid rgb(244, 85, 85);
  border-radius: 15px;

  font-family: "Arial";
  font-weight: 657;
  font-size: 11px;
  line-height: 16px;

  align-items: center;
  text-transform: uppercase;

  color: rgb(244, 85, 85);
  font-variation-settings: "slnt" -1;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.lang-button {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  width: 30%;
  margin-top: 10px;
  height: 50px;

  background: #828181;
  mix-blend-mode: darken;
  border: 3px solid #ffffff;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.2);
  border-radius: 15px;

  font-family: "Arial";
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
  font-variation-settings: "slnt" -1;
}

.text {
  font-family: "Arial";
  font-style: normal;
  font-weight: 530;
  font-size: 13px;
  line-height: 17px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.42);
  margin-left: auto;
  margin-right: auto;
}

.red-text {
  color: rgba(175, 53, 53, 0.765);
  font-weight: 560;
}


.link {
  font-family: "Arial";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: rgba(42, 173, 182, 0.87);
}

.title {
  font-family: "Arial";
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #929292;
  font-variation-settings: "slnt" -1;
  

}

.blue {
  color: #2aadb6 !important;
}

.big-blue-title {
  position: relative;
  color: #2aadb6;
  font-family: "Arial";
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 1px 1px 6px #929292;
  margin-left: auto;
  margin-right: auto;

}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px 11px;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 10px;
  margin-left: 15%;

  width: 70%;
  height: 70px;

  background: #2aadb6;
  mix-blend-mode: darken;
  border: 3px solid #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 15px;

  font-family: "Arial";
  font-weight: 657;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: #ffffff;
  font-variation-settings: "slnt" -1;

  
  /* Note: backdrop-filter has minimal browser support */
}

.add-image-button {
  display: inline-block;
  background: rgba(217, 217, 217, 0.75);
  border: 3px solid #ffffff;
  box-shadow: 0px 4px 13px rgba(90, 90, 90, 0.25);

  font-family: "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  color: #ffffff;

  justify-content: center;
  align-items: center;
  border-radius: 5%;
}

.dummy {
  margin-top: 110%;
}

.hide {
  overflow: hidden;
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.round {
  border-radius: 50%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-file {
  display: inline-block;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 10px 10px 10px;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 30px;

  width: 70%;
  height: 70px;
  margin-left: 15%;

  background: #ffffff;
  mix-blend-mode: darken;
  border: 3px solid #2aadb6;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 15px;

  font-family: "Arial";
  font-weight: 657;
  font-size: 13px;
  line-height: 16px;

  align-items: center;
  text-transform: uppercase;

  color: #2aadb6;
  font-variation-settings: "slnt" -1;
}

.disabled {
  border: 3px solid #a2a0a0 !important;
  color: #a2a0a0 !important;
}

.remove-button {
  display: inline-block;
  flex-direction: row;
  align-items: flex-start;

  width: 20%;
  height: 30px;
  margin-left: 4%;

  background: #ffffff !important;
  mix-blend-mode: darken;
  border: 3px solid rgb(244, 85, 85);
  border-radius: 15px;

  font-family: "Arial";
  font-weight: 657;
  font-size: 11px;
  line-height: 16px;

  align-items: center;
  text-transform: uppercase;

  color: rgb(244, 85, 85);
  font-variation-settings: "slnt" -1;
}

.input {
  display: inline-block;
  margin-top: 30px;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 0px 8px 10px;
  grid-gap: 10px;
  gap: 10px;
  width: 70%;
  margin-left: 13%;

  height: 40px;

  background: #f4f4f4;
  mix-blend-mode: darken;
  border: 3px solid #f4f4f4;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 15px;

  font-family: "Arial";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  
}

::-webkit-input-placeholder {
  color: #b5b5b5;
  opacity: 1; /* Firefox */
  text-transform: uppercase;
}

::placeholder {
  color: #b5b5b5;
  opacity: 1; /* Firefox */
  text-transform: uppercase;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b5b5b5;
  text-transform: uppercase;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b5b5b5;
  text-transform: uppercase;
}

.text-area {
  height: 150px;
}

.red {
  box-shadow: 0px 0px 10px rgba(245, 1, 1, 0.4);
}

.round {
  border-radius: 50% !important;
}

.image {
  position: relative;
  /* width: 70%;
  height: 150px; */
  left: 15%;

  border: 3px solid #FFFFFF;
  box-shadow: 0px 4px 13px rgba(90, 90, 90, 0.25);
  border-radius: 5%;
}

.video {
  width: 80%;
  margin-left: 10%;
  mix-blend-mode: darken;
  border: 3px solid #2aadb6;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

.card {
  display: inline-block;
  margin-top: 30px;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px 0px 8px 10px;
  grid-gap: 10px;
  gap: 10px;

  margin-left: 12%;
  width: 70%;
  height: 100px;

  background: #f4f4f4;
  mix-blend-mode: darken;
  border: 3px solid #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

